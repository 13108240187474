import "../../styles/main.css";
import "./ContactForm.scss";
import "../layout/Footer/Footer.css";

import React from "react";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import { send } from "emailjs-com";

import Button1 from "../../svg/buttons/button1.svg";
import { Link } from "gatsby-plugin-react-i18next";

const FORM_ENDPOINT = "";

export default function ContactForm({ mensajeText, section, submit }) {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [toSend, setToSend] = React.useState({
		from_email: "",
		name: "n/a",
		local: "n/a",
		message: "",
		section: section,
		data_protection: "No",
	});

	// console.log(toSend);

	const handleSubmit = (e) => {
		e.preventDefault();

		setLoading(true);

		send("BeginMail", "FormWeb", toSend, "user_W5hHvWhHwCiPlZypRr0Np")
			.then((response) => {
				submit(true, response);
			})
			.catch((err) => {
				submit(false, err);
			});
	};

	const handleChange = (e) => {
		if (e.target.type === "checkbox") {
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.checked
						? "Si. Fecha: " +
						  new Date() +
						  ". Texto: " +
						  e.target?.labels[0]?.innerText
						: "No",
				};
			});
		}
		return setToSend((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	return (
		<div>
			<div>
				<form
					action={FORM_ENDPOINT}
					onSubmit={handleSubmit}
					onChange={handleChange}
					method="POST"
					target="_blank"
					className="contactForm"
				>
					<div>
						<label htmlFor="from_email">
							<p>
								<Trans>Déjanos tu email</Trans>:
							</p>
						</label>
						<input
							type="email"
							placeholder={t("Email")}
							id="from_email"
							name="from_email"
							title=" "
							required
						/>
					</div>

					<div>
						<label htmlFor="message">
							<p>{mensajeText}:</p>
						</label>
						<textarea
							placeholder={t("Mensaje")}
							name="message"
							id="message"
							title=" "
							required
						/>
					</div>

					<div className="dataProtection">
						<p>
							<Trans ns="dataProtection">
								Información básica sobre protección de datos
							</Trans>
							:
						</p>
						<table>
							<tbody>
								<tr>
									<td>
										<Trans ns="dataProtection">Responsable</Trans>:
									</td>
									<td>BEGIN RESTAURANTES, S.L.</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Domicilio</Trans>:
									</td>
									<td>
										Av. Cortes Valencianas, 50, CP 46015 Valencia (Valencia)
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Finalidad</Trans>:
									</td>
									<td>
										<Trans ns="dataProtection">
											Atender su solicitud de información
										</Trans>
										.
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Legitimacion</Trans>
									</td>
									<td>
										<Trans ns="dataProtection">
											Sus datos serán tratados solo con su consentimiento, al
											marcar la casilla mostrada en este formulario
										</Trans>
										.
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Destinatarios</Trans>:
									</td>
									<td>
										<Trans ns="dataProtection">
											Sus datos no serán cedidos a terceros
										</Trans>
										.
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Derechos</Trans>:
									</td>
									<td>
										<Trans ns="dataProtection">
											Tiene derecho a solicitarnos acceder a sus datos,
											corregirlos o eliminarlos, también puede solicitarnos
											limitar su tratamiento, oponerse a ello y a la
											portabilidad de sus datos, dirigiéndose a nuestra
											dirección postal o a
										</Trans>{" "}
										<a href="mailto:contacto@beginrestaurante.com">
											contacto@beginrestaurante.com
										</a>
									</td>
								</tr>
								<tr>
									<td>
										<Trans ns="dataProtection">Mas info</Trans>:
									</td>
									<td>
										<Trans ns="dataProtection">
											Dispone de más información en nuestra
										</Trans>{" "}
										<Link to="/legal/pollitica-privacidad">
											<Trans ns="dataProtection">Política de Privacidad</Trans>
										</Link>
									</td>
								</tr>
							</tbody>
						</table>

						<div className="dataCheck">
							<input
								type="checkbox"
								name="data_protection"
								id="data_protection"
								required
							/>
							<label
								htmlFor="data_protection"
								aria-label={t(
									"Consiento el uso de mis datos personales para que atiendan mi solicitud"
								)}
							>
								<p>
									<Trans ns="dataProtection">
										Consiento el uso de mis datos personales para que atiendan
										mi solicitud, según lo establecido en su{" "}
										<Link to="/legal/pollitica-privacidad">
											<Trans ns="dataProtection">Política de Privacidad</Trans>
										</Link>{" "}
										y declaro tener cumplidos los catorce (14) años de edad,
										asumiendo las posibles responsabilidades legales sobre la
										veracidad de esta declaración.
									</Trans>
								</p>
							</label>
						</div>
					</div>

					<div className="formSubmit">
						{loading ? (
							<p>Enviando...</p>
						) : (
							<>
								<button type="submit" aria-label={t("Enviar")}>
									<Button1 />
									<h3>
										<Trans>Enviar</Trans>
									</h3>
								</button>
							</>
						)}
					</div>
				</form>
			</div>
		</div>
	);
}
