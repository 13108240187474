import "./contacto.css";

import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Layout from "../../components/layout/Layout";
import Seo from "../../components/seo";
import { Link } from "gatsby-plugin-react-i18next";
import ContactForm from "../../components/contact/ContactForm";
import JoinUsForm from "../../components/contact/JoinUsForm";
// import BookForm from "../../components/contact/BookForm";

import IconInstagram from "../../svg/icons/iconRRSSInstagram.svg";
import IconTikTok from "../../svg/icons/iconRRSSTikTok.svg";
import Subrayado1 from "../../svg/subrayados/subrayado01.svg";
import Subrayado4 from "../../svg/subrayados/subrayado04.svg";
import Subrayado5 from "../../svg/subrayados/subrayado05.svg";

export default function IndexPage() {
	const { t } = useTranslation();
	const [currentSection, toggleSection] = React.useState("");

	const mailSubmited = (success, response) => {
		if (success) {
			toggleSection("enviado");
			console.log("Mensaje enviado", response.status, response.text);
		} else {
			toggleSection("errorEnvio");
			console.log("Error enviando mensaje", response);
		}
	};

	if (typeof window !== "undefined") {
		// Update currentSection on hash change
		window.onhashchange = (e) => {
			let newSection = window.location.hash.slice(1);
			if (newSection !== currentSection) {
				toggleSection(newSection);
				// console.log(newSection)
			}
		};
	}

	React.useEffect(() => {
		if (typeof window !== "undefined") {
			const linkedSection = window.location.hash.slice(1);
			if (linkedSection !== "") toggleSection(linkedSection);
			window.scrollTo(0, 0);
		}
	}, []);

	return (
		<Layout footerText={t("¿Cómo se llama este capítulo de tu vida?")}>
			<Seo title={t("Contacto")} />
			<StaticImage
				src={`../../images/contacto.jpg`}
				className="landing-bg contactoBg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="contactoBody">
				<SwitchTransition mode="out-in">
					<CSSTransition key={currentSection} timeout={400} classNames="fade">
						<div className="contactoText">
							{{
								sobreTi: (
									<p>
										<Trans>
											Si estás aquí suponemos que{" "}
											<a href="#cuidarse">
												quieres cuidarte
												<Subrayado5 />
											</a>
											, te gustaría{" "}
											<a href="#colaborar">
												colaborar
												<Subrayado4 />
											</a>{" "}
											con nosotros, eres alguien de la competencia{" "}
											<a href="#competencia">
												buscando inspiración
												<Subrayado5 />
											</a>
											{/* , quieres{" "}
											<a href="#reserva">
												gestionar tu reserva
												<Subrayado5 />
											</a>{" "} */}{" "}
											o simplemente quieres{" "}
											<a href="#saberMas">
												saber más
												<Subrayado4 />
											</a>{" "}
											sobre nuestro proyecto de conexión.
										</Trans>
									</p>
								),
								cuidarse: (
									<div>
										<p>
											<Trans>
												Imaginamos que tienes alguna duda, quieres aportar algo
												para que podamos mejorar o sencillamente quieres
												comentarnos cualquier otra cosa. Sea lo que sea, estamos
												encantados de escucharte.
											</Trans>
										</p>

										<ContactForm
											mensajeText={t("Te escuchamos")}
											section="Dudas"
											submit={mailSubmited}
										/>
									</div>
								),
								colaborar: (
									<div>
										<p>
											<Trans>
												Así que quieres aportar tu granito de arena a este
												proyecto… Pues estamos deseando que nos cuentes tus
												ideas más geniales para poder seguir mejorando.
											</Trans>
										</p>
										<JoinUsForm
											mensajeText={t("Cuéntanos")}
											section="Colabora"
											submit={mailSubmited}
										/>
									</div>
								),
								competencia: (
									<p>
										<Trans>
											Nos encanta tener colegas de profesión por aquí. Si
											quieres inspiración acerca de cómo intentamos respetar el
											planeta, te aconsejamos que te pases por{" "}
											<Link to="/compromiso">
												aquí
												<Subrayado1 />
											</Link>{" "}
											para ver nuestro compromiso con el entorno.
										</Trans>
									</p>
								),
								// reserva: (
								// 	<div>
								// 		<p>
								// 			<Trans i18nKey="contactReserva">
								// 				Para hacer una reserva accede a nuestro{" "}
								// 				<Link to="/reserva">
								// 					calendario de reservas <Subrayado5 />
								// 				</Link>{" "}
								// 				haciendo click
								// 				<Link to="/reserva">
								// 					aquí
								// 					<Subrayado1 />
								// 				</Link>
								// 				.
								// 				<br />
								// 				<br />
								// 				Para cualquier otra consulta sobre tu reserva dinos...
								// 			</Trans>
								// 		</p>

								// 		<BookForm
								// 			mensajeText={t(
								// 				"Dinos qué necesitas (no te dejes ningún detalle importante)"
								// 			)}
								// 			section="Reservas"
								// 			submit={mailSubmited}
								// 		/>
								// 	</div>
								// ),
								saberMas: (
									<div>
										<p>
											<Trans i18nKey="contactSaberMas">
												Así que quieres saber más...
												<br />
												En esta web puedes conocer mejor nuestras acciones y
												valores, aunque si quieres vivir la experiencia completa
												te invitamos a que vengas a visitarnos.
												<br /> <br />
												Si quieres saber más en detalle sobre nuestra
												responsabilidad con el planeta te invitamos a que te
												pases por{" "}
												<Link to="/compromiso">
													aquí
													<Subrayado1 />
												</Link>
												.
												<br />
												Para cualquier otra información que quieras saber...
											</Trans>
										</p>

										<ContactForm
											mensajeText={t("Pregunta lo que quieras (es gratis)")}
											section="Saber más"
											submit={mailSubmited}
										/>
									</div>
								),
								enviado: (
									<div>
										<p>
											<Trans>
												Pronto nos pondremos en contacto contigo, chequea spam
												:)
											</Trans>
										</p>
										<p>
											<Trans>Mientras esperas...</Trans>
										</p>
										<div className="RRSS">
											<a
												href="https://www.instagram.com/beginrestaurante/"
												aria-label="Instagram"
											>
												<IconInstagram />
											</a>
											<a
												href="https://www.tiktok.com/@beginrestaurante"
												aria-label="TikTok"
											>
												<IconTikTok />
											</a>
										</div>
									</div>
								),
								errorEnvio: (
									<div>
										<p>
											<Trans>
												¡Ups! Ha habido un error en el envío del mensaje. ¡Lo
												sentimos!
											</Trans>
										</p>
										<p>
											<Trans>
												Vuelve a intentarlo en unos momentos. Si el problema
												persiste, también puedes contactarnos a través del chat
												o de nuestras Redes Sociales:
											</Trans>
										</p>
										<div className="RRSS">
											<a
												href="https://www.instagram.com/beginrestaurante/"
												aria-label="Instagram"
											>
												<IconInstagram />
											</a>
											<a
												href="https://www.tiktok.com/@beginrestaurante"
												aria-label="TikTok"
											>
												<IconTikTok />
											</a>
										</div>
									</div>
								),
							}[currentSection] || (
								<Trans i18nKey="contact1">
									<h2>Hola, ¿qué tal?</h2>
									<p>
										Somos Begin, un proyecto que trabaja en construir un futuro
										más conectado con la naturaleza a través de comida de Origen
										y experiencias de conexión únicas.
									</p>
									<p>
										Pero, dejemos de hablar de nosotros, has venido hasta aquí
										por algo,{" "}
										<a href="#sobreTi">
											cuéntanos sobre ti
											<Subrayado5 />
										</a>
										.
									</p>
								</Trans>
							)}
						</div>
					</CSSTransition>
				</SwitchTransition>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
